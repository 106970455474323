var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',{staticClass:"p-[11px]"},[_c('div',{staticClass:"text-black text-2xl font-semibold mb-1"},[_vm._v(" Ubah Training Program ")]),_c('b-overlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('validation-observer',{ref:"formRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama","rules":{ regex: /^[a-zA-Z0-9 ]*$/, min:3 , required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Auto","state":errors.length > 0 || _vm.submitErrors.name ? false : null,"formatter":_vm.capitalize,"disabled":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jenis Event","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Jenis event","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.eventOptions},on:{"change":function($event){return _vm.handleChangeType($event)}},model:{value:(_vm.eventType),callback:function ($$v) {_vm.eventType=$$v},expression:"eventType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"p-0",attrs:{"label":"Training Center","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Training Center","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Pilih Training Center","label":'name',"clearable":_vm.eventType === 'online' ? false : true,"options":_vm.listTrainingCenter,"reduce":function (tc) { return tc.id; },"disabled":_vm.eventType === 'online'},on:{"input":function($event){return _vm.handleSelectTC($event)}},model:{value:(_vm.trainingCenterId),callback:function ($$v) {_vm.trainingCenterId=$$v},expression:"trainingCenterId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Batch","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Batch","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Auto","state":errors.length > 0 || _vm.submitErrors.name ? false : null,"formatter":_vm.capitalize,"disabled":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kuota","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Kuota","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Contoh: 80","state":errors.length > 0 || _vm.submitErrors.name ? false : null,"formatter":_vm.capitalize},model:{value:(_vm.quota),callback:function ($$v) {_vm.quota=$$v},expression:"quota"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"p-0",attrs:{"label":"Skill Role","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Skill Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Pilih Skill Role","label":'name',"clearable":true,"options":_vm.listSkill,"reduce":function (skill) { return skill.id; }},on:{"input":function($event){return _vm.selectSkillRole()}},model:{value:(_vm.skillId),callback:function ($$v) {_vm.skillId=$$v},expression:"skillId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"p-0",attrs:{"label":"Kurikulum","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Kurikulum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Pilih Kurikulum","label":'name',"clearable":true,"options":_vm.listCurriculum,"reduce":function (curriculum) { return curriculum.id; },"disabled":_vm.listCurriculum.length > 0 ? false : true},model:{value:(_vm.curriculumId),callback:function ($$v) {_vm.curriculumId=$$v},expression:"curriculumId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Keterampilan Lain","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Keterampilan Lain","rules":{ regex: /^[a-zA-Z0-9 ]*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"otherSkill",staticClass:"w-100",attrs:{"placeholder":"Masukkan keterampilan","label":'name',"clearable":true,"options":_vm.listOtherSkill,"reduce":function (skill) { return skill.id; },"multiple":true},model:{value:(_vm.otherSkills),callback:function ($$v) {_vm.otherSkills=$$v},expression:"otherSkills"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"p-0",attrs:{"label":"Trainer","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Trainer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Pilih Trainer","label":'name',"clearable":true,"options":_vm.listTrainer,"reduce":function (trainer) { return trainer.id; }},model:{value:(_vm.trainerId),callback:function ($$v) {_vm.trainerId=$$v},expression:"trainerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"p-0",attrs:{"label":"Tanggal","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group form-control p-0 pl-1"},[_c('flat-pickr',{attrs:{"config":_vm.config},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('div',{staticClass:"input-group-append"},[_c('BButton',{staticClass:"input-button",attrs:{"variant":"flat-warning","size":"md","data-toggle":""}},[_c('span',{staticClass:"k-calendar-2"})])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"mr-50 button-secondary",attrs:{"type":"kembali"},on:{"click":function($event){$event.preventDefault();return _vm.$router.back()}}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"mr-50 button-primary w-[200px]",attrs:{"disabled":invalid || _vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }